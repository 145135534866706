.snow {
  width: 30px;
  height: 30px;
  opacity: .5;
  background: #fff;
  border-radius: 50%;
  position: absolute;
}

.snow:nth-child(1) {
  opacity: .4599;
  animation: 27s linear -24s infinite fall-1;
  transform: translate(13.5041vw, -10px)scale(.6913);
}

@keyframes fall-1 {
  68.497% {
    transform: translate(8.5576vw, 68.497vh)scale(.6913);
  }

  to {
    transform: translate(11.0309vw, 100vh)scale(.6913);
  }
}

.snow:nth-child(2) {
  opacity: .942;
  animation: 14s linear -10s infinite fall-2;
  transform: translate(50.9372vw, -10px)scale(.8921);
}

@keyframes fall-2 {
  50.142% {
    transform: translate(49.9473vw, 50.142vh)scale(.8921);
  }

  to {
    transform: translate(50.4423vw, 100vh)scale(.8921);
  }
}

.snow:nth-child(3) {
  opacity: .1696;
  animation: 11s linear -2s infinite fall-3;
  transform: translate(8.8832vw, -10px)scale(.1337);
}

@keyframes fall-3 {
  72.746% {
    transform: translate(14.0818vw, 72.746vh)scale(.1337);
  }

  to {
    transform: translate(11.4825vw, 100vh)scale(.1337);
  }
}

.snow:nth-child(4) {
  opacity: .8182;
  animation: 11s linear -21s infinite fall-4;
  transform: translate(69.7116vw, -10px)scale(.2746);
}

@keyframes fall-4 {
  56.003% {
    transform: translate(74.1327vw, 56.003vh)scale(.2746);
  }

  to {
    transform: translate(71.9222vw, 100vh)scale(.2746);
  }
}

.snow:nth-child(5) {
  opacity: .5917;
  animation: 25s linear -12s infinite fall-5;
  transform: translate(36.7719vw, -10px)scale(.6778);
}

@keyframes fall-5 {
  60.336% {
    transform: translate(42.0819vw, 60.336vh)scale(.6778);
  }

  to {
    transform: translate(39.4269vw, 100vh)scale(.6778);
  }
}

.snow:nth-child(6) {
  opacity: .2549;
  animation: 12s linear -21s infinite fall-6;
  transform: translate(90.5254vw, -10px)scale(.2747);
}

@keyframes fall-6 {
  36.722% {
    transform: translate(97.4977vw, 36.722vh)scale(.2747);
  }

  to {
    transform: translate(94.0116vw, 100vh)scale(.2747);
  }
}

.snow:nth-child(7) {
  opacity: .715;
  animation: 18s linear -17s infinite fall-7;
  transform: translate(98.5018vw, -10px)scale(.2341);
}

@keyframes fall-7 {
  52.977% {
    transform: translate(97.8026vw, 52.977vh)scale(.2341);
  }

  to {
    transform: translate(98.1522vw, 100vh)scale(.2341);
  }
}

.snow:nth-child(8) {
  opacity: .4024;
  animation: 10s linear -18s infinite fall-8;
  transform: translate(62.0127vw, -10px)scale(.7205);
}

@keyframes fall-8 {
  74.623% {
    transform: translate(66.3669vw, 74.623vh)scale(.7205);
  }

  to {
    transform: translate(64.1898vw, 100vh)scale(.7205);
  }
}

.snow:nth-child(9) {
  opacity: .3719;
  animation: 15s linear -13s infinite fall-9;
  transform: translate(35.9259vw, -10px)scale(.0349);
}

@keyframes fall-9 {
  73.906% {
    transform: translate(42.2338vw, 73.906vh)scale(.0349);
  }

  to {
    transform: translate(39.0799vw, 100vh)scale(.0349);
  }
}

.snow:nth-child(10) {
  opacity: .8692;
  animation: 21s linear -27s infinite fall-10;
  transform: translate(72.9836vw, -10px)scale(.3155);
}

@keyframes fall-10 {
  34.341% {
    transform: translate(79.4451vw, 34.341vh)scale(.3155);
  }

  to {
    transform: translate(76.2144vw, 100vh)scale(.3155);
  }
}

.snow:nth-child(11) {
  opacity: .0147;
  animation: 23s linear -3s infinite fall-11;
  transform: translate(78.4479vw, -10px)scale(.7032);
}

@keyframes fall-11 {
  65.629% {
    transform: translate(81.0677vw, 65.629vh)scale(.7032);
  }

  to {
    transform: translate(79.7578vw, 100vh)scale(.7032);
  }
}

.snow:nth-child(12) {
  opacity: .1842;
  animation: 14s linear -10s infinite fall-12;
  transform: translate(41.4466vw, -10px)scale(.7037);
}

@keyframes fall-12 {
  76.543% {
    transform: translate(46.8664vw, 76.543vh)scale(.7037);
  }

  to {
    transform: translate(44.1565vw, 100vh)scale(.7037);
  }
}

.snow:nth-child(13) {
  opacity: .7948;
  animation: 16s linear -14s infinite fall-13;
  transform: translate(13.3635vw, -10px)scale(.5001);
}

@keyframes fall-13 {
  34.436% {
    transform: translate(16.1709vw, 34.436vh)scale(.5001);
  }

  to {
    transform: translate(14.7672vw, 100vh)scale(.5001);
  }
}

.snow:nth-child(14) {
  opacity: .9307;
  animation: 13s linear -6s infinite fall-14;
  transform: translate(50.1651vw, -10px)scale(.1168);
}

@keyframes fall-14 {
  72.548% {
    transform: translate(59.5163vw, 72.548vh)scale(.1168);
  }

  to {
    transform: translate(54.8407vw, 100vh)scale(.1168);
  }
}

.snow:nth-child(15) {
  opacity: .5052;
  animation: 25s linear -27s infinite fall-15;
  transform: translate(25.7924vw, -10px)scale(.3321);
}

@keyframes fall-15 {
  30.023% {
    transform: translate(35.0524vw, 30.023vh)scale(.3321);
  }

  to {
    transform: translate(30.4224vw, 100vh)scale(.3321);
  }
}

.snow:nth-child(16) {
  opacity: .0177;
  animation: 30s linear -3s infinite fall-16;
  transform: translate(77.5042vw, -10px)scale(.0048);
}

@keyframes fall-16 {
  43.241% {
    transform: translate(83.1128vw, 43.241vh)scale(.0048);
  }

  to {
    transform: translate(80.3085vw, 100vh)scale(.0048);
  }
}

.snow:nth-child(17) {
  opacity: .3109;
  animation: 26s linear -17s infinite fall-17;
  transform: translate(14.3555vw, -10px)scale(.1438);
}

@keyframes fall-17 {
  74.511% {
    transform: translate(7.3128vw, 74.511vh)scale(.1438);
  }

  to {
    transform: translate(10.8342vw, 100vh)scale(.1438);
  }
}

.snow:nth-child(18) {
  opacity: .7751;
  animation: 10s linear -18s infinite fall-18;
  transform: translate(46.1825vw, -10px)scale(.0671);
}

@keyframes fall-18 {
  46.173% {
    transform: translate(38.2052vw, 46.173vh)scale(.0671);
  }

  to {
    transform: translate(42.1939vw, 100vh)scale(.0671);
  }
}

.snow:nth-child(19) {
  opacity: .9814;
  animation: 19s linear -11s infinite fall-19;
  transform: translate(27.2908vw, -10px)scale(.8179);
}

@keyframes fall-19 {
  36.869% {
    transform: translate(29.9668vw, 36.869vh)scale(.8179);
  }

  to {
    transform: translate(28.6288vw, 100vh)scale(.8179);
  }
}

.snow:nth-child(20) {
  opacity: .2179;
  animation: 22s linear -22s infinite fall-20;
  transform: translate(20.1083vw, -10px)scale(.8994);
}

@keyframes fall-20 {
  58.387% {
    transform: translate(14.8398vw, 58.387vh)scale(.8994);
  }

  to {
    transform: translate(17.4741vw, 100vh)scale(.8994);
  }
}

.snow:nth-child(21) {
  opacity: .6469;
  animation: 20s linear -23s infinite fall-21;
  transform: translate(31.646vw, -10px)scale(.9321);
}

@keyframes fall-21 {
  42.882% {
    transform: translate(35.6762vw, 42.882vh)scale(.9321);
  }

  to {
    transform: translate(33.6611vw, 100vh)scale(.9321);
  }
}

.snow:nth-child(22) {
  opacity: .7266;
  animation: 24s linear -10s infinite fall-22;
  transform: translate(74.2899vw, -10px)scale(.811);
}

@keyframes fall-22 {
  51.708% {
    transform: translate(76.5045vw, 51.708vh)scale(.811);
  }

  to {
    transform: translate(75.3972vw, 100vh)scale(.811);
  }
}

.snow:nth-child(23) {
  opacity: .7685;
  animation: 29s linear -14s infinite fall-23;
  transform: translate(55.0176vw, -10px)scale(.715);
}

@keyframes fall-23 {
  34.289% {
    transform: translate(61.9654vw, 34.289vh)scale(.715);
  }

  to {
    transform: translate(58.4915vw, 100vh)scale(.715);
  }
}

.snow:nth-child(24) {
  opacity: .2239;
  animation: 16s linear -14s infinite fall-24;
  transform: translate(4.0209vw, -10px)scale(.9706);
}

@keyframes fall-24 {
  48.896% {
    transform: translate(5.9301vw, 48.896vh)scale(.9706);
  }

  to {
    transform: translate(4.9755vw, 100vh)scale(.9706);
  }
}

.snow:nth-child(25) {
  opacity: .2912;
  animation: 19s linear -30s infinite fall-25;
  transform: translate(27.7305vw, -10px)scale(.1002);
}

@keyframes fall-25 {
  55.683% {
    transform: translate(34.7262vw, 55.683vh)scale(.1002);
  }

  to {
    transform: translate(31.2284vw, 100vh)scale(.1002);
  }
}

.snow:nth-child(26) {
  opacity: .0476;
  animation: 20s linear -2s infinite fall-26;
  transform: translate(61.2273vw, -10px)scale(.4247);
}

@keyframes fall-26 {
  31.114% {
    transform: translate(68.6598vw, 31.114vh)scale(.4247);
  }

  to {
    transform: translate(64.9436vw, 100vh)scale(.4247);
  }
}

.snow:nth-child(27) {
  opacity: .7524;
  animation: 14s linear -25s infinite fall-27;
  transform: translate(48.1984vw, -10px)scale(.1538);
}

@keyframes fall-27 {
  41.541% {
    transform: translate(46.5327vw, 41.541vh)scale(.1538);
  }

  to {
    transform: translate(47.3656vw, 100vh)scale(.1538);
  }
}

.snow:nth-child(28) {
  opacity: .859;
  animation: 26s linear -12s infinite fall-28;
  transform: translate(58.5304vw, -10px)scale(.4316);
}

@keyframes fall-28 {
  49.972% {
    transform: translate(65.1619vw, 49.972vh)scale(.4316);
  }

  to {
    transform: translate(61.8462vw, 100vh)scale(.4316);
  }
}

.snow:nth-child(29) {
  opacity: .7292;
  animation: 23s linear -3s infinite fall-29;
  transform: translate(95.404vw, -10px)scale(.8999);
}

@keyframes fall-29 {
  67.254% {
    transform: translate(104.491vw, 67.254vh)scale(.8999);
  }

  to {
    transform: translate(99.9475vw, 100vh)scale(.8999);
  }
}

.snow:nth-child(30) {
  opacity: .8929;
  animation: 29s linear -14s infinite fall-30;
  transform: translate(36.3895vw, -10px)scale(.0083);
}

@keyframes fall-30 {
  56.797% {
    transform: translate(34.8865vw, 56.797vh)scale(.0083);
  }

  to {
    transform: translate(35.638vw, 100vh)scale(.0083);
  }
}

.snow:nth-child(31) {
  opacity: .2936;
  animation: 16s linear -7s infinite fall-31;
  transform: translate(76.3776vw, -10px)scale(.0433);
}

@keyframes fall-31 {
  78.421% {
    transform: translate(69.2219vw, 78.421vh)scale(.0433);
  }

  to {
    transform: translate(72.7998vw, 100vh)scale(.0433);
  }
}

.snow:nth-child(32) {
  opacity: .4315;
  animation: 11s linear -18s infinite fall-32;
  transform: translate(37.8048vw, -10px)scale(.515);
}

@keyframes fall-32 {
  69.192% {
    transform: translate(35.8024vw, 69.192vh)scale(.515);
  }

  to {
    transform: translate(36.8036vw, 100vh)scale(.515);
  }
}

.snow:nth-child(33) {
  opacity: .1242;
  animation: 17s linear -7s infinite fall-33;
  transform: translate(69.3346vw, -10px)scale(.6922);
}

@keyframes fall-33 {
  68.913% {
    transform: translate(68.4465vw, 68.913vh)scale(.6922);
  }

  to {
    transform: translate(68.8906vw, 100vh)scale(.6922);
  }
}

.snow:nth-child(34) {
  opacity: .6379;
  animation: 27s linear -22s infinite fall-34;
  transform: translate(97.6908vw, -10px)scale(.9679);
}

@keyframes fall-34 {
  43.623% {
    transform: translate(88.8639vw, 43.623vh)scale(.9679);
  }

  to {
    transform: translate(93.2774vw, 100vh)scale(.9679);
  }
}

.snow:nth-child(35) {
  opacity: .7992;
  animation: 26s linear -21s infinite fall-35;
  transform: translate(49.7096vw, -10px)scale(.5261);
}

@keyframes fall-35 {
  78.124% {
    transform: translate(49.1825vw, 78.124vh)scale(.5261);
  }

  to {
    transform: translate(49.4461vw, 100vh)scale(.5261);
  }
}

.snow:nth-child(36) {
  opacity: .2103;
  animation: 28s linear -21s infinite fall-36;
  transform: translate(25.5558vw, -10px)scale(.9652);
}

@keyframes fall-36 {
  74.982% {
    transform: translate(24.2784vw, 74.982vh)scale(.9652);
  }

  to {
    transform: translate(24.9171vw, 100vh)scale(.9652);
  }
}

.snow:nth-child(37) {
  opacity: .58;
  animation: 26s linear -25s infinite fall-37;
  transform: translate(72.4285vw, -10px)scale(.2375);
}

@keyframes fall-37 {
  67.467% {
    transform: translate(78.5905vw, 67.467vh)scale(.2375);
  }

  to {
    transform: translate(75.5095vw, 100vh)scale(.2375);
  }
}

.snow:nth-child(38) {
  opacity: .4864;
  animation: 27s linear -30s infinite fall-38;
  transform: translate(68.0664vw, -10px)scale(.1446);
}

@keyframes fall-38 {
  41.42% {
    transform: translate(58.9133vw, 41.42vh)scale(.1446);
  }

  to {
    transform: translate(63.4899vw, 100vh)scale(.1446);
  }
}

.snow:nth-child(39) {
  opacity: .8058;
  animation: 25s linear -24s infinite fall-39;
  transform: translate(95.6823vw, -10px)scale(.9289);
}

@keyframes fall-39 {
  59.616% {
    transform: translate(94.794vw, 59.616vh)scale(.9289);
  }

  to {
    transform: translate(95.2382vw, 100vh)scale(.9289);
  }
}

.snow:nth-child(40) {
  opacity: .0877;
  animation: 14s linear -15s infinite fall-40;
  transform: translate(44.9462vw, -10px)scale(.2278);
}

@keyframes fall-40 {
  65.035% {
    transform: translate(54.15vw, 65.035vh)scale(.2278);
  }

  to {
    transform: translate(49.5481vw, 100vh)scale(.2278);
  }
}

.snow:nth-child(41) {
  opacity: .5499;
  animation: 14s linear -12s infinite fall-41;
  transform: translate(51.9055vw, -10px)scale(.8902);
}

@keyframes fall-41 {
  41.762% {
    transform: translate(43.4852vw, 41.762vh)scale(.8902);
  }

  to {
    transform: translate(47.6954vw, 100vh)scale(.8902);
  }
}

.snow:nth-child(42) {
  opacity: .9792;
  animation: 24s linear -11s infinite fall-42;
  transform: translate(19.8383vw, -10px)scale(.9347);
}

@keyframes fall-42 {
  41.466% {
    transform: translate(13.4634vw, 41.466vh)scale(.9347);
  }

  to {
    transform: translate(16.6509vw, 100vh)scale(.9347);
  }
}

.snow:nth-child(43) {
  opacity: .7121;
  animation: 16s linear -24s infinite fall-43;
  transform: translate(48.9476vw, -10px)scale(.3336);
}

@keyframes fall-43 {
  66.982% {
    transform: translate(42.9165vw, 66.982vh)scale(.3336);
  }

  to {
    transform: translate(45.9321vw, 100vh)scale(.3336);
  }
}

.snow:nth-child(44) {
  opacity: .1421;
  animation: 12s linear -30s infinite fall-44;
  transform: translate(17.9809vw, -10px)scale(.3832);
}

@keyframes fall-44 {
  72.518% {
    transform: translate(11.0884vw, 72.518vh)scale(.3832);
  }

  to {
    transform: translate(14.5347vw, 100vh)scale(.3832);
  }
}

.snow:nth-child(45) {
  opacity: .5632;
  animation: 27s linear -29s infinite fall-45;
  transform: translate(86.8356vw, -10px)scale(.1288);
}

@keyframes fall-45 {
  72.41% {
    transform: translate(76.9804vw, 72.41vh)scale(.1288);
  }

  to {
    transform: translate(81.908vw, 100vh)scale(.1288);
  }
}

.snow:nth-child(46) {
  opacity: .591;
  animation: 22s linear -20s infinite fall-46;
  transform: translate(85.3835vw, -10px)scale(.4243);
}

@keyframes fall-46 {
  48.202% {
    transform: translate(85.3958vw, 48.202vh)scale(.4243);
  }

  to {
    transform: translate(85.3897vw, 100vh)scale(.4243);
  }
}

.snow:nth-child(47) {
  opacity: .4214;
  animation: 28s linear -29s infinite fall-47;
  transform: translate(83.5173vw, -10px)scale(.2105);
}

@keyframes fall-47 {
  76.048% {
    transform: translate(76.0935vw, 76.048vh)scale(.2105);
  }

  to {
    transform: translate(79.8054vw, 100vh)scale(.2105);
  }
}

.snow:nth-child(48) {
  opacity: .1657;
  animation: 10s linear -29s infinite fall-48;
  transform: translate(38.9195vw, -10px)scale(.8417);
}

@keyframes fall-48 {
  71.119% {
    transform: translate(41.328vw, 71.119vh)scale(.8417);
  }

  to {
    transform: translate(40.1238vw, 100vh)scale(.8417);
  }
}

.snow:nth-child(49) {
  opacity: .7677;
  animation: 13s linear -5s infinite fall-49;
  transform: translate(94.9324vw, -10px)scale(.793);
}

@keyframes fall-49 {
  58.325% {
    transform: translate(99.5393vw, 58.325vh)scale(.793);
  }

  to {
    transform: translate(97.2359vw, 100vh)scale(.793);
  }
}

.snow:nth-child(50) {
  opacity: .3828;
  animation: 28s linear -7s infinite fall-50;
  transform: translate(97.0356vw, -10px)scale(.2866);
}

@keyframes fall-50 {
  32.157% {
    transform: translate(96.7466vw, 32.157vh)scale(.2866);
  }

  to {
    transform: translate(96.8911vw, 100vh)scale(.2866);
  }
}

.snow:nth-child(51) {
  opacity: .2873;
  animation: 28s linear -9s infinite fall-51;
  transform: translate(48.6057vw, -10px)scale(.1628);
}

@keyframes fall-51 {
  47.759% {
    transform: translate(50.4685vw, 47.759vh)scale(.1628);
  }

  to {
    transform: translate(49.5371vw, 100vh)scale(.1628);
  }
}

.snow:nth-child(52) {
  opacity: .5519;
  animation: 19s linear -9s infinite fall-52;
  transform: translate(31.0681vw, -10px)scale(.8973);
}

@keyframes fall-52 {
  61.626% {
    transform: translate(29.1382vw, 61.626vh)scale(.8973);
  }

  to {
    transform: translate(30.1032vw, 100vh)scale(.8973);
  }
}

.snow:nth-child(53) {
  opacity: .3955;
  animation: 11s linear -16s infinite fall-53;
  transform: translate(37.2715vw, -10px)scale(.0397);
}

@keyframes fall-53 {
  47.791% {
    transform: translate(46.2931vw, 47.791vh)scale(.0397);
  }

  to {
    transform: translate(41.7823vw, 100vh)scale(.0397);
  }
}

.snow:nth-child(54) {
  opacity: .0873;
  animation: 22s linear -1s infinite fall-54;
  transform: translate(38.0226vw, -10px)scale(.5027);
}

@keyframes fall-54 {
  44.05% {
    transform: translate(40.9281vw, 44.05vh)scale(.5027);
  }

  to {
    transform: translate(39.4754vw, 100vh)scale(.5027);
  }
}

.snow:nth-child(55) {
  opacity: .7304;
  animation: 27s linear -5s infinite fall-55;
  transform: translate(16.114vw, -10px)scale(.1149);
}

@keyframes fall-55 {
  72.715% {
    transform: translate(9.8294vw, 72.715vh)scale(.1149);
  }

  to {
    transform: translate(12.9717vw, 100vh)scale(.1149);
  }
}

.snow:nth-child(56) {
  opacity: .1587;
  animation: 11s linear -28s infinite fall-56;
  transform: translate(29.0781vw, -10px)scale(.9079);
}

@keyframes fall-56 {
  58.639% {
    transform: translate(27.1437vw, 58.639vh)scale(.9079);
  }

  to {
    transform: translate(28.1109vw, 100vh)scale(.9079);
  }
}

.snow:nth-child(57) {
  opacity: .2995;
  animation: 14s linear -30s infinite fall-57;
  transform: translate(26.9184vw, -10px)scale(.4374);
}

@keyframes fall-57 {
  47.773% {
    transform: translate(35.4413vw, 47.773vh)scale(.4374);
  }

  to {
    transform: translate(31.1799vw, 100vh)scale(.4374);
  }
}

.snow:nth-child(58) {
  opacity: .1652;
  animation: 25s linear -22s infinite fall-58;
  transform: translate(78.3986vw, -10px)scale(.8813);
}

@keyframes fall-58 {
  69.434% {
    transform: translate(70.9678vw, 69.434vh)scale(.8813);
  }

  to {
    transform: translate(74.6832vw, 100vh)scale(.8813);
  }
}

.snow:nth-child(59) {
  opacity: .5242;
  animation: 12s linear -13s infinite fall-59;
  transform: translate(60.6583vw, -10px)scale(.1485);
}

@keyframes fall-59 {
  57.421% {
    transform: translate(62.2624vw, 57.421vh)scale(.1485);
  }

  to {
    transform: translate(61.4604vw, 100vh)scale(.1485);
  }
}

.snow:nth-child(60) {
  opacity: .1463;
  animation: 16s linear -20s infinite fall-60;
  transform: translate(76.4094vw, -10px)scale(.7883);
}

@keyframes fall-60 {
  41.203% {
    transform: translate(77.886vw, 41.203vh)scale(.7883);
  }

  to {
    transform: translate(77.1477vw, 100vh)scale(.7883);
  }
}

.snow:nth-child(61) {
  opacity: .0808;
  animation: 22s linear -22s infinite fall-61;
  transform: translate(76.8736vw, -10px)scale(.8211);
}

@keyframes fall-61 {
  51.76% {
    transform: translate(77.1253vw, 51.76vh)scale(.8211);
  }

  to {
    transform: translate(76.9995vw, 100vh)scale(.8211);
  }
}

.snow:nth-child(62) {
  opacity: .2471;
  animation: 16s linear -10s infinite fall-62;
  transform: translate(66.3152vw, -10px)scale(.4174);
}

@keyframes fall-62 {
  40.69% {
    transform: translate(61.5018vw, 40.69vh)scale(.4174);
  }

  to {
    transform: translate(63.9085vw, 100vh)scale(.4174);
  }
}

.snow:nth-child(63) {
  opacity: .8681;
  animation: 27s linear -16s infinite fall-63;
  transform: translate(93.3164vw, -10px)scale(.5525);
}

@keyframes fall-63 {
  74.626% {
    transform: translate(83.558vw, 74.626vh)scale(.5525);
  }

  to {
    transform: translate(88.4372vw, 100vh)scale(.5525);
  }
}

.snow:nth-child(64) {
  opacity: .7164;
  animation: 10s linear -15s infinite fall-64;
  transform: translate(78.8781vw, -10px)scale(.0388);
}

@keyframes fall-64 {
  55.413% {
    transform: translate(78.171vw, 55.413vh)scale(.0388);
  }

  to {
    transform: translate(78.5246vw, 100vh)scale(.0388);
  }
}

.snow:nth-child(65) {
  opacity: .5618;
  animation: 12s linear -22s infinite fall-65;
  transform: translate(80.3745vw, -10px)scale(.7307);
}

@keyframes fall-65 {
  72.109% {
    transform: translate(76.4572vw, 72.109vh)scale(.7307);
  }

  to {
    transform: translate(78.4159vw, 100vh)scale(.7307);
  }
}

.snow:nth-child(66) {
  opacity: .1727;
  animation: 16s linear -26s infinite fall-66;
  transform: translate(15.8019vw, -10px)scale(.3622);
}

@keyframes fall-66 {
  54.371% {
    transform: translate(6.1776vw, 54.371vh)scale(.3622);
  }

  to {
    transform: translate(10.9898vw, 100vh)scale(.3622);
  }
}

.snow:nth-child(67) {
  opacity: .5834;
  animation: 14s linear -23s infinite fall-67;
  transform: translate(45.1135vw, -10px)scale(.6072);
}

@keyframes fall-67 {
  54.762% {
    transform: translate(36.0473vw, 54.762vh)scale(.6072);
  }

  to {
    transform: translate(40.5804vw, 100vh)scale(.6072);
  }
}

.snow:nth-child(68) {
  opacity: .213;
  animation: 12s linear -14s infinite fall-68;
  transform: translate(29.0464vw, -10px)scale(.7958);
}

@keyframes fall-68 {
  67.119% {
    transform: translate(25.4221vw, 67.119vh)scale(.7958);
  }

  to {
    transform: translate(27.2343vw, 100vh)scale(.7958);
  }
}

.snow:nth-child(69) {
  opacity: .4911;
  animation: 19s linear -29s infinite fall-69;
  transform: translate(72.1515vw, -10px)scale(.2138);
}

@keyframes fall-69 {
  71.062% {
    transform: translate(77.6091vw, 71.062vh)scale(.2138);
  }

  to {
    transform: translate(74.8803vw, 100vh)scale(.2138);
  }
}

.snow:nth-child(70) {
  opacity: .1696;
  animation: 10s linear -1s infinite fall-70;
  transform: translate(32.8639vw, -10px)scale(.8331);
}

@keyframes fall-70 {
  44.343% {
    transform: translate(30.2444vw, 44.343vh)scale(.8331);
  }

  to {
    transform: translate(31.5542vw, 100vh)scale(.8331);
  }
}

.snow:nth-child(71) {
  opacity: .5903;
  animation: 15s linear -15s infinite fall-71;
  transform: translate(2.9372vw, -10px)scale(.3092);
}

@keyframes fall-71 {
  79.691% {
    transform: translate(11.6056vw, 79.691vh)scale(.3092);
  }

  to {
    transform: translate(7.2714vw, 100vh)scale(.3092);
  }
}

.snow:nth-child(72) {
  opacity: .9003;
  animation: 19s linear -19s infinite fall-72;
  transform: translate(19.5063vw, -10px)scale(.9247);
}

@keyframes fall-72 {
  40.452% {
    transform: translate(29.2492vw, 40.452vh)scale(.9247);
  }

  to {
    transform: translate(24.3778vw, 100vh)scale(.9247);
  }
}

.snow:nth-child(73) {
  opacity: .9221;
  animation: 26s linear -12s infinite fall-73;
  transform: translate(72.7329vw, -10px)scale(.105);
}

@keyframes fall-73 {
  56.779% {
    transform: translate(64.4701vw, 56.779vh)scale(.105);
  }

  to {
    transform: translate(68.6015vw, 100vh)scale(.105);
  }
}

.snow:nth-child(74) {
  opacity: .9228;
  animation: 22s linear -29s infinite fall-74;
  transform: translate(75.3362vw, -10px)scale(.3779);
}

@keyframes fall-74 {
  47.248% {
    transform: translate(84.4455vw, 47.248vh)scale(.3779);
  }

  to {
    transform: translate(79.8909vw, 100vh)scale(.3779);
  }
}

.snow:nth-child(75) {
  opacity: .1488;
  animation: 28s linear -5s infinite fall-75;
  transform: translate(12.6682vw, -10px)scale(.0575);
}

@keyframes fall-75 {
  51.938% {
    transform: translate(17.2912vw, 51.938vh)scale(.0575);
  }

  to {
    transform: translate(14.9797vw, 100vh)scale(.0575);
  }
}

.snow:nth-child(76) {
  opacity: .8861;
  animation: 11s linear -11s infinite fall-76;
  transform: translate(30.091vw, -10px)scale(.9033);
}

@keyframes fall-76 {
  77.79% {
    transform: translate(24.9108vw, 77.79vh)scale(.9033);
  }

  to {
    transform: translate(27.5009vw, 100vh)scale(.9033);
  }
}

.snow:nth-child(77) {
  opacity: .2474;
  animation: 24s linear -9s infinite fall-77;
  transform: translate(28.2745vw, -10px)scale(.6309);
}

@keyframes fall-77 {
  68.485% {
    transform: translate(35.972vw, 68.485vh)scale(.6309);
  }

  to {
    transform: translate(32.1233vw, 100vh)scale(.6309);
  }
}

.snow:nth-child(78) {
  opacity: .6797;
  animation: 29s linear -26s infinite fall-78;
  transform: translate(57.8646vw, -10px)scale(.6684);
}

@keyframes fall-78 {
  61.335% {
    transform: translate(61.2151vw, 61.335vh)scale(.6684);
  }

  to {
    transform: translate(59.5399vw, 100vh)scale(.6684);
  }
}

.snow:nth-child(79) {
  opacity: .791;
  animation: 20s linear -4s infinite fall-79;
  transform: translate(69.5745vw, -10px)scale(.7586);
}

@keyframes fall-79 {
  63.15% {
    transform: translate(73.1084vw, 63.15vh)scale(.7586);
  }

  to {
    transform: translate(71.3415vw, 100vh)scale(.7586);
  }
}

.snow:nth-child(80) {
  opacity: .1702;
  animation: 10s linear -3s infinite fall-80;
  transform: translate(74.7432vw, -10px)scale(.7452);
}

@keyframes fall-80 {
  46.497% {
    transform: translate(67.704vw, 46.497vh)scale(.7452);
  }

  to {
    transform: translate(71.2236vw, 100vh)scale(.7452);
  }
}

.snow:nth-child(81) {
  opacity: .6235;
  animation: 12s linear -19s infinite fall-81;
  transform: translate(82.715vw, -10px)scale(.8566);
}

@keyframes fall-81 {
  76.359% {
    transform: translate(89.1841vw, 76.359vh)scale(.8566);
  }

  to {
    transform: translate(85.9496vw, 100vh)scale(.8566);
  }
}

.snow:nth-child(82) {
  opacity: .603;
  animation: 13s linear -22s infinite fall-82;
  transform: translate(70.4358vw, -10px)scale(.4422);
}

@keyframes fall-82 {
  71.213% {
    transform: translate(61.2387vw, 71.213vh)scale(.4422);
  }

  to {
    transform: translate(65.8373vw, 100vh)scale(.4422);
  }
}

.snow:nth-child(83) {
  opacity: .5941;
  animation: 18s linear -2s infinite fall-83;
  transform: translate(38.6901vw, -10px)scale(.2484);
}

@keyframes fall-83 {
  63.647% {
    transform: translate(32.6046vw, 63.647vh)scale(.2484);
  }

  to {
    transform: translate(35.6474vw, 100vh)scale(.2484);
  }
}

.snow:nth-child(84) {
  opacity: .3059;
  animation: 24s linear -27s infinite fall-84;
  transform: translate(95.6156vw, -10px)scale(.9304);
}

@keyframes fall-84 {
  66.25% {
    transform: translate(92.3826vw, 66.25vh)scale(.9304);
  }

  to {
    transform: translate(93.9991vw, 100vh)scale(.9304);
  }
}

.snow:nth-child(85) {
  opacity: .5496;
  animation: 27s linear -4s infinite fall-85;
  transform: translate(50.5435vw, -10px)scale(.3679);
}

@keyframes fall-85 {
  71.15% {
    transform: translate(51.1615vw, 71.15vh)scale(.3679);
  }

  to {
    transform: translate(50.8525vw, 100vh)scale(.3679);
  }
}

.snow:nth-child(86) {
  opacity: .2413;
  animation: 18s linear -1s infinite fall-86;
  transform: translate(12.4835vw, -10px)scale(.9323);
}

@keyframes fall-86 {
  66.092% {
    transform: translate(6.1383vw, 66.092vh)scale(.9323);
  }

  to {
    transform: translate(9.3109vw, 100vh)scale(.9323);
  }
}

.snow:nth-child(87) {
  opacity: .307;
  animation: 10s linear -1s infinite fall-87;
  transform: translate(85.6443vw, -10px)scale(.4679);
}

@keyframes fall-87 {
  78.337% {
    transform: translate(78.4407vw, 78.337vh)scale(.4679);
  }

  to {
    transform: translate(82.0425vw, 100vh)scale(.4679);
  }
}

.snow:nth-child(88) {
  opacity: .884;
  animation: 25s linear -3s infinite fall-88;
  transform: translate(81.2253vw, -10px)scale(.7906);
}

@keyframes fall-88 {
  38.615% {
    transform: translate(78.2417vw, 38.615vh)scale(.7906);
  }

  to {
    transform: translate(79.7335vw, 100vh)scale(.7906);
  }
}

.snow:nth-child(89) {
  opacity: .3072;
  animation: 12s linear -17s infinite fall-89;
  transform: translate(48.7349vw, -10px)scale(.7414);
}

@keyframes fall-89 {
  54.719% {
    transform: translate(54.5162vw, 54.719vh)scale(.7414);
  }

  to {
    transform: translate(51.6256vw, 100vh)scale(.7414);
  }
}

.snow:nth-child(90) {
  opacity: .2843;
  animation: 23s linear -20s infinite fall-90;
  transform: translate(58.2538vw, -10px)scale(.3917);
}

@keyframes fall-90 {
  71.229% {
    transform: translate(66.7218vw, 71.229vh)scale(.3917);
  }

  to {
    transform: translate(62.4878vw, 100vh)scale(.3917);
  }
}

.snow:nth-child(91) {
  opacity: .9101;
  animation: 18s linear -26s infinite fall-91;
  transform: translate(44.1908vw, -10px)scale(.0378);
}

@keyframes fall-91 {
  58.775% {
    transform: translate(36.9535vw, 58.775vh)scale(.0378);
  }

  to {
    transform: translate(40.5722vw, 100vh)scale(.0378);
  }
}

.snow:nth-child(92) {
  opacity: .8531;
  animation: 30s linear -27s infinite fall-92;
  transform: translate(92.9237vw, -10px)scale(.7021);
}

@keyframes fall-92 {
  77.823% {
    transform: translate(91.0719vw, 77.823vh)scale(.7021);
  }

  to {
    transform: translate(91.9978vw, 100vh)scale(.7021);
  }
}

.snow:nth-child(93) {
  opacity: .9153;
  animation: 26s linear -5s infinite fall-93;
  transform: translate(42.8443vw, -10px)scale(.0445);
}

@keyframes fall-93 {
  42.401% {
    transform: translate(35.6646vw, 42.401vh)scale(.0445);
  }

  to {
    transform: translate(39.2545vw, 100vh)scale(.0445);
  }
}

.snow:nth-child(94) {
  opacity: .088;
  animation: 14s linear -23s infinite fall-94;
  transform: translate(2.5955vw, -10px)scale(.6248);
}

@keyframes fall-94 {
  52.555% {
    transform: translate(5.2706vw, 52.555vh)scale(.6248);
  }

  to {
    transform: translate(3.93305vw, 100vh)scale(.6248);
  }
}

.snow:nth-child(95) {
  opacity: .1939;
  animation: 15s linear -17s infinite fall-95;
  transform: translate(47.768vw, -10px)scale(.6134);
}

@keyframes fall-95 {
  47.555% {
    transform: translate(41.6237vw, 47.555vh)scale(.6134);
  }

  to {
    transform: translate(44.6959vw, 100vh)scale(.6134);
  }
}

.snow:nth-child(96) {
  opacity: .9842;
  animation: 26s linear -20s infinite fall-96;
  transform: translate(25.0313vw, -10px)scale(.9755);
}

@keyframes fall-96 {
  67.167% {
    transform: translate(27.2072vw, 67.167vh)scale(.9755);
  }

  to {
    transform: translate(26.1193vw, 100vh)scale(.9755);
  }
}

.snow:nth-child(97) {
  opacity: .5916;
  animation: 28s linear -27s infinite fall-97;
  transform: translate(12.1616vw, -10px)scale(.0943);
}

@keyframes fall-97 {
  66.191% {
    transform: translate(5.8075vw, 66.191vh)scale(.0943);
  }

  to {
    transform: translate(8.98455vw, 100vh)scale(.0943);
  }
}

.snow:nth-child(98) {
  opacity: .0563;
  animation: 20s linear -25s infinite fall-98;
  transform: translate(79.2442vw, -10px)scale(.5364);
}

@keyframes fall-98 {
  54.822% {
    transform: translate(78.2797vw, 54.822vh)scale(.5364);
  }

  to {
    transform: translate(78.762vw, 100vh)scale(.5364);
  }
}

.snow:nth-child(99) {
  opacity: .5554;
  animation: 20s linear -28s infinite fall-99;
  transform: translate(22.7171vw, -10px)scale(.9658);
}

@keyframes fall-99 {
  40.086% {
    transform: translate(12.886vw, 40.086vh)scale(.9658);
  }

  to {
    transform: translate(17.8016vw, 100vh)scale(.9658);
  }
}

.snow:nth-child(100) {
  opacity: .825;
  animation: 20s linear -3s infinite fall-100;
  transform: translate(96.4083vw, -10px)scale(.3632);
}

@keyframes fall-100 {
  30.66% {
    transform: translate(92.7906vw, 30.66vh)scale(.3632);
  }

  to {
    transform: translate(94.5995vw, 100vh)scale(.3632);
  }
}

.snow:nth-child(101) {
  opacity: .0418;
  animation: 27s linear -4s infinite fall-101;
  transform: translate(31.1564vw, -10px)scale(.9933);
}

@keyframes fall-101 {
  49.618% {
    transform: translate(27.1874vw, 49.618vh)scale(.9933);
  }

  to {
    transform: translate(29.1719vw, 100vh)scale(.9933);
  }
}

.snow:nth-child(102) {
  opacity: .3622;
  animation: 24s linear -1s infinite fall-102;
  transform: translate(73.4689vw, -10px)scale(.7244);
}

@keyframes fall-102 {
  72.829% {
    transform: translate(79.4265vw, 72.829vh)scale(.7244);
  }

  to {
    transform: translate(76.4477vw, 100vh)scale(.7244);
  }
}

.snow:nth-child(103) {
  opacity: .0923;
  animation: 17s linear -2s infinite fall-103;
  transform: translate(15.4056vw, -10px)scale(.4046);
}

@keyframes fall-103 {
  47.843% {
    transform: translate(7.5614vw, 47.843vh)scale(.4046);
  }

  to {
    transform: translate(11.4835vw, 100vh)scale(.4046);
  }
}

.snow:nth-child(104) {
  opacity: .2946;
  animation: 23s linear -15s infinite fall-104;
  transform: translate(30.566vw, -10px)scale(.7602);
}

@keyframes fall-104 {
  57.425% {
    transform: translate(36.2054vw, 57.425vh)scale(.7602);
  }

  to {
    transform: translate(33.3857vw, 100vh)scale(.7602);
  }
}

.snow:nth-child(105) {
  opacity: .4889;
  animation: 27s linear -16s infinite fall-105;
  transform: translate(4.2768vw, -10px)scale(.9809);
}

@keyframes fall-105 {
  30.852% {
    transform: translate(6.5274vw, 30.852vh)scale(.9809);
  }

  to {
    transform: translate(5.4021vw, 100vh)scale(.9809);
  }
}

.snow:nth-child(106) {
  opacity: .2273;
  animation: 23s linear -16s infinite fall-106;
  transform: translate(19.8695vw, -10px)scale(.1004);
}

@keyframes fall-106 {
  69.769% {
    transform: translate(19.1746vw, 69.769vh)scale(.1004);
  }

  to {
    transform: translate(19.5221vw, 100vh)scale(.1004);
  }
}

.snow:nth-child(107) {
  opacity: .2978;
  animation: 26s linear -20s infinite fall-107;
  transform: translate(36.8935vw, -10px)scale(.1077);
}

@keyframes fall-107 {
  30.319% {
    transform: translate(36.2501vw, 30.319vh)scale(.1077);
  }

  to {
    transform: translate(36.5718vw, 100vh)scale(.1077);
  }
}

.snow:nth-child(108) {
  opacity: .5463;
  animation: 17s linear -18s infinite fall-108;
  transform: translate(87.9592vw, -10px)scale(.1978);
}

@keyframes fall-108 {
  33.06% {
    transform: translate(80.3894vw, 33.06vh)scale(.1978);
  }

  to {
    transform: translate(84.1743vw, 100vh)scale(.1978);
  }
}

.snow:nth-child(109) {
  opacity: .6518;
  animation: 13s linear -27s infinite fall-109;
  transform: translate(23.6283vw, -10px)scale(.5029);
}

@keyframes fall-109 {
  55.874% {
    transform: translate(24.6194vw, 55.874vh)scale(.5029);
  }

  to {
    transform: translate(24.1239vw, 100vh)scale(.5029);
  }
}

.snow:nth-child(110) {
  opacity: .8012;
  animation: 12s linear -25s infinite fall-110;
  transform: translate(96.1005vw, -10px)scale(.1637);
}

@keyframes fall-110 {
  39.945% {
    transform: translate(99.3519vw, 39.945vh)scale(.1637);
  }

  to {
    transform: translate(97.7262vw, 100vh)scale(.1637);
  }
}

.snow:nth-child(111) {
  opacity: .1724;
  animation: 10s linear -1s infinite fall-111;
  transform: translate(50.0045vw, -10px)scale(.1707);
}

@keyframes fall-111 {
  30.449% {
    transform: translate(42.0238vw, 30.449vh)scale(.1707);
  }

  to {
    transform: translate(46.0142vw, 100vh)scale(.1707);
  }
}

.snow:nth-child(112) {
  opacity: .7763;
  animation: 14s linear -10s infinite fall-112;
  transform: translate(66.672vw, -10px)scale(.6491);
}

@keyframes fall-112 {
  71.299% {
    transform: translate(57.0604vw, 71.299vh)scale(.6491);
  }

  to {
    transform: translate(61.8662vw, 100vh)scale(.6491);
  }
}

.snow:nth-child(113) {
  opacity: .0313;
  animation: 16s linear -14s infinite fall-113;
  transform: translate(66.5494vw, -10px)scale(.8745);
}

@keyframes fall-113 {
  53.673% {
    transform: translate(72.1804vw, 53.673vh)scale(.8745);
  }

  to {
    transform: translate(69.3649vw, 100vh)scale(.8745);
  }
}

.snow:nth-child(114) {
  opacity: .9513;
  animation: 19s linear -30s infinite fall-114;
  transform: translate(29.9119vw, -10px)scale(.8122);
}

@keyframes fall-114 {
  68.795% {
    transform: translate(30.1307vw, 68.795vh)scale(.8122);
  }

  to {
    transform: translate(30.0213vw, 100vh)scale(.8122);
  }
}

.snow:nth-child(115) {
  opacity: .194;
  animation: 29s linear -20s infinite fall-115;
  transform: translate(54.4825vw, -10px)scale(.2618);
}

@keyframes fall-115 {
  45.987% {
    transform: translate(60.426vw, 45.987vh)scale(.2618);
  }

  to {
    transform: translate(57.4543vw, 100vh)scale(.2618);
  }
}

.snow:nth-child(116) {
  opacity: .4189;
  animation: 29s linear -20s infinite fall-116;
  transform: translate(79.0381vw, -10px)scale(.3477);
}

@keyframes fall-116 {
  73.835% {
    transform: translate(73.3832vw, 73.835vh)scale(.3477);
  }

  to {
    transform: translate(76.2107vw, 100vh)scale(.3477);
  }
}

.snow:nth-child(117) {
  opacity: .2508;
  animation: 19s linear -7s infinite fall-117;
  transform: translate(8.6427vw, -10px)scale(.5109);
}

@keyframes fall-117 {
  47.204% {
    transform: translate(.73vw, 47.204vh)scale(.5109);
  }

  to {
    transform: translate(4.68635vw, 100vh)scale(.5109);
  }
}

.snow:nth-child(118) {
  opacity: .3879;
  animation: 26s linear -10s infinite fall-118;
  transform: translate(42.5798vw, -10px)scale(.5271);
}

@keyframes fall-118 {
  40.508% {
    transform: translate(42.9222vw, 40.508vh)scale(.5271);
  }

  to {
    transform: translate(42.751vw, 100vh)scale(.5271);
  }
}

.snow:nth-child(119) {
  opacity: .6057;
  animation: 13s linear -17s infinite fall-119;
  transform: translate(55.3034vw, -10px)scale(.719);
}

@keyframes fall-119 {
  32.517% {
    transform: translate(64.4vw, 32.517vh)scale(.719);
  }

  to {
    transform: translate(59.8517vw, 100vh)scale(.719);
  }
}

.snow:nth-child(120) {
  opacity: .4683;
  animation: 12s linear -11s infinite fall-120;
  transform: translate(77.9434vw, -10px)scale(.8802);
}

@keyframes fall-120 {
  33.838% {
    transform: translate(69.5148vw, 33.838vh)scale(.8802);
  }

  to {
    transform: translate(73.7291vw, 100vh)scale(.8802);
  }
}

.snow:nth-child(121) {
  opacity: .5045;
  animation: 25s linear -9s infinite fall-121;
  transform: translate(67.6902vw, -10px)scale(.581);
}

@keyframes fall-121 {
  74.131% {
    transform: translate(73.9206vw, 74.131vh)scale(.581);
  }

  to {
    transform: translate(70.8054vw, 100vh)scale(.581);
  }
}

.snow:nth-child(122) {
  opacity: .1862;
  animation: 25s linear -17s infinite fall-122;
  transform: translate(96.0527vw, -10px)scale(.3624);
}

@keyframes fall-122 {
  47.236% {
    transform: translate(88.3759vw, 47.236vh)scale(.3624);
  }

  to {
    transform: translate(92.2143vw, 100vh)scale(.3624);
  }
}

.snow:nth-child(123) {
  opacity: .5506;
  animation: 22s linear -14s infinite fall-123;
  transform: translate(86.3188vw, -10px)scale(.013);
}

@keyframes fall-123 {
  79.779% {
    transform: translate(84.0644vw, 79.779vh)scale(.013);
  }

  to {
    transform: translate(85.1916vw, 100vh)scale(.013);
  }
}

.snow:nth-child(124) {
  opacity: .0622;
  animation: 18s linear -6s infinite fall-124;
  transform: translate(16.1627vw, -10px)scale(.4227);
}

@keyframes fall-124 {
  71.392% {
    transform: translate(21.972vw, 71.392vh)scale(.4227);
  }

  to {
    transform: translate(19.0674vw, 100vh)scale(.4227);
  }
}

.snow:nth-child(125) {
  opacity: .4987;
  animation: 17s linear -21s infinite fall-125;
  transform: translate(77.9008vw, -10px)scale(.729);
}

@keyframes fall-125 {
  74.723% {
    transform: translate(79.0946vw, 74.723vh)scale(.729);
  }

  to {
    transform: translate(78.4977vw, 100vh)scale(.729);
  }
}

.snow:nth-child(126) {
  opacity: .4814;
  animation: 25s linear -1s infinite fall-126;
  transform: translate(80.7811vw, -10px)scale(.6891);
}

@keyframes fall-126 {
  51.649% {
    transform: translate(79.6782vw, 51.649vh)scale(.6891);
  }

  to {
    transform: translate(80.2297vw, 100vh)scale(.6891);
  }
}

.snow:nth-child(127) {
  opacity: .8656;
  animation: 11s linear -1s infinite fall-127;
  transform: translate(49.8844vw, -10px)scale(.2438);
}

@keyframes fall-127 {
  64.639% {
    transform: translate(42.2726vw, 64.639vh)scale(.2438);
  }

  to {
    transform: translate(46.0785vw, 100vh)scale(.2438);
  }
}

.snow:nth-child(128) {
  opacity: .1238;
  animation: 11s linear -27s infinite fall-128;
  transform: translate(36.6003vw, -10px)scale(.0637);
}

@keyframes fall-128 {
  75.715% {
    transform: translate(32.4942vw, 75.715vh)scale(.0637);
  }

  to {
    transform: translate(34.5473vw, 100vh)scale(.0637);
  }
}

.snow:nth-child(129) {
  opacity: .0857;
  animation: 18s linear -15s infinite fall-129;
  transform: translate(98.756vw, -10px)scale(.6515);
}

@keyframes fall-129 {
  42.449% {
    transform: translate(107.052vw, 42.449vh)scale(.6515);
  }

  to {
    transform: translate(102.904vw, 100vh)scale(.6515);
  }
}

.snow:nth-child(130) {
  opacity: .6123;
  animation: 13s linear -9s infinite fall-130;
  transform: translate(70.9638vw, -10px)scale(.7469);
}

@keyframes fall-130 {
  73.205% {
    transform: translate(77.9809vw, 73.205vh)scale(.7469);
  }

  to {
    transform: translate(74.4724vw, 100vh)scale(.7469);
  }
}

.snow:nth-child(131) {
  opacity: .0899;
  animation: 24s linear -19s infinite fall-131;
  transform: translate(45.9995vw, -10px)scale(.7027);
}

@keyframes fall-131 {
  47.405% {
    transform: translate(52.7164vw, 47.405vh)scale(.7027);
  }

  to {
    transform: translate(49.358vw, 100vh)scale(.7027);
  }
}

.snow:nth-child(132) {
  opacity: .251;
  animation: 13s linear -14s infinite fall-132;
  transform: translate(44.2897vw, -10px)scale(.7342);
}

@keyframes fall-132 {
  77.165% {
    transform: translate(48.9893vw, 77.165vh)scale(.7342);
  }

  to {
    transform: translate(46.6395vw, 100vh)scale(.7342);
  }
}

.snow:nth-child(133) {
  opacity: .4301;
  animation: 29s linear -30s infinite fall-133;
  transform: translate(92.9434vw, -10px)scale(.9985);
}

@keyframes fall-133 {
  79.276% {
    transform: translate(86.0719vw, 79.276vh)scale(.9985);
  }

  to {
    transform: translate(89.5077vw, 100vh)scale(.9985);
  }
}

.snow:nth-child(134) {
  opacity: .1114;
  animation: 16s linear -2s infinite fall-134;
  transform: translate(23.9683vw, -10px)scale(.2531);
}

@keyframes fall-134 {
  32.969% {
    transform: translate(25.9308vw, 32.969vh)scale(.2531);
  }

  to {
    transform: translate(24.9496vw, 100vh)scale(.2531);
  }
}

.snow:nth-child(135) {
  opacity: .0914;
  animation: 21s linear -16s infinite fall-135;
  transform: translate(17.3978vw, -10px)scale(.3325);
}

@keyframes fall-135 {
  61.81% {
    transform: translate(24.2273vw, 61.81vh)scale(.3325);
  }

  to {
    transform: translate(20.8126vw, 100vh)scale(.3325);
  }
}

.snow:nth-child(136) {
  opacity: .9303;
  animation: 10s linear -1s infinite fall-136;
  transform: translate(92.8158vw, -10px)scale(.0772);
}

@keyframes fall-136 {
  49.569% {
    transform: translate(85.4573vw, 49.569vh)scale(.0772);
  }

  to {
    transform: translate(89.1366vw, 100vh)scale(.0772);
  }
}

.snow:nth-child(137) {
  opacity: .1939;
  animation: 14s linear -3s infinite fall-137;
  transform: translate(62.2403vw, -10px)scale(.6326);
}

@keyframes fall-137 {
  52.093% {
    transform: translate(71.1808vw, 52.093vh)scale(.6326);
  }

  to {
    transform: translate(66.7106vw, 100vh)scale(.6326);
  }
}

.snow:nth-child(138) {
  opacity: .8627;
  animation: 25s linear -27s infinite fall-138;
  transform: translate(38.9916vw, -10px)scale(.2469);
}

@keyframes fall-138 {
  79.003% {
    transform: translate(33.7286vw, 79.003vh)scale(.2469);
  }

  to {
    transform: translate(36.3601vw, 100vh)scale(.2469);
  }
}

.snow:nth-child(139) {
  opacity: .1873;
  animation: 29s linear -13s infinite fall-139;
  transform: translate(61.5239vw, -10px)scale(.3478);
}

@keyframes fall-139 {
  57.007% {
    transform: translate(51.6503vw, 57.007vh)scale(.3478);
  }

  to {
    transform: translate(56.5871vw, 100vh)scale(.3478);
  }
}

.snow:nth-child(140) {
  opacity: .8733;
  animation: 28s linear -12s infinite fall-140;
  transform: translate(28.9202vw, -10px)scale(.343);
}

@keyframes fall-140 {
  52.091% {
    transform: translate(37.3762vw, 52.091vh)scale(.343);
  }

  to {
    transform: translate(33.1482vw, 100vh)scale(.343);
  }
}

.snow:nth-child(141) {
  opacity: .1986;
  animation: 22s linear -29s infinite fall-141;
  transform: translate(38.2723vw, -10px)scale(.4891);
}

@keyframes fall-141 {
  45.722% {
    transform: translate(36.7866vw, 45.722vh)scale(.4891);
  }

  to {
    transform: translate(37.5295vw, 100vh)scale(.4891);
  }
}

.snow:nth-child(142) {
  opacity: .6177;
  animation: 21s linear -5s infinite fall-142;
  transform: translate(83.2026vw, -10px)scale(.8616);
}

@keyframes fall-142 {
  52.699% {
    transform: translate(78.596vw, 52.699vh)scale(.8616);
  }

  to {
    transform: translate(80.8993vw, 100vh)scale(.8616);
  }
}

.snow:nth-child(143) {
  opacity: .7296;
  animation: 21s linear -20s infinite fall-143;
  transform: translate(96.5514vw, -10px)scale(.8424);
}

@keyframes fall-143 {
  56.202% {
    transform: translate(101.071vw, 56.202vh)scale(.8424);
  }

  to {
    transform: translate(98.811vw, 100vh)scale(.8424);
  }
}

.snow:nth-child(144) {
  opacity: .6849;
  animation: 16s linear -24s infinite fall-144;
  transform: translate(74.1304vw, -10px)scale(.1003);
}

@keyframes fall-144 {
  63.095% {
    transform: translate(70.8006vw, 63.095vh)scale(.1003);
  }

  to {
    transform: translate(72.4655vw, 100vh)scale(.1003);
  }
}

.snow:nth-child(145) {
  opacity: .2154;
  animation: 22s linear -15s infinite fall-145;
  transform: translate(54.5303vw, -10px)scale(.5017);
}

@keyframes fall-145 {
  73.744% {
    transform: translate(49.4621vw, 73.744vh)scale(.5017);
  }

  to {
    transform: translate(51.9962vw, 100vh)scale(.5017);
  }
}

.snow:nth-child(146) {
  opacity: .9603;
  animation: 28s linear -13s infinite fall-146;
  transform: translate(76.3281vw, -10px)scale(.6139);
}

@keyframes fall-146 {
  75.422% {
    transform: translate(76.4468vw, 75.422vh)scale(.6139);
  }

  to {
    transform: translate(76.3875vw, 100vh)scale(.6139);
  }
}

.snow:nth-child(147) {
  opacity: .8002;
  animation: 28s linear -4s infinite fall-147;
  transform: translate(11.9567vw, -10px)scale(.3646);
}

@keyframes fall-147 {
  66.072% {
    transform: translate(5.7871vw, 66.072vh)scale(.3646);
  }

  to {
    transform: translate(8.8719vw, 100vh)scale(.3646);
  }
}

.snow:nth-child(148) {
  opacity: .5312;
  animation: 15s linear -2s infinite fall-148;
  transform: translate(94.2038vw, -10px)scale(.9209);
}

@keyframes fall-148 {
  60.994% {
    transform: translate(102.217vw, 60.994vh)scale(.9209);
  }

  to {
    transform: translate(98.2104vw, 100vh)scale(.9209);
  }
}

.snow:nth-child(149) {
  opacity: .9385;
  animation: 19s linear -29s infinite fall-149;
  transform: translate(59.7501vw, -10px)scale(.4375);
}

@keyframes fall-149 {
  73.105% {
    transform: translate(68.2806vw, 73.105vh)scale(.4375);
  }

  to {
    transform: translate(64.0154vw, 100vh)scale(.4375);
  }
}

.snow:nth-child(150) {
  opacity: .0687;
  animation: 25s linear -28s infinite fall-150;
  transform: translate(17.8005vw, -10px)scale(.1737);
}

@keyframes fall-150 {
  42.746% {
    transform: translate(21.7063vw, 42.746vh)scale(.1737);
  }

  to {
    transform: translate(19.7534vw, 100vh)scale(.1737);
  }
}

.snow:nth-child(151) {
  opacity: .2748;
  animation: 17s linear -26s infinite fall-151;
  transform: translate(29.0687vw, -10px)scale(.7779);
}

@keyframes fall-151 {
  33.068% {
    transform: translate(33.9427vw, 33.068vh)scale(.7779);
  }

  to {
    transform: translate(31.5057vw, 100vh)scale(.7779);
  }
}

.snow:nth-child(152) {
  opacity: .6347;
  animation: 17s linear -1s infinite fall-152;
  transform: translate(72.5817vw, -10px)scale(.7949);
}

@keyframes fall-152 {
  31.068% {
    transform: translate(76.9412vw, 31.068vh)scale(.7949);
  }

  to {
    transform: translate(74.7615vw, 100vh)scale(.7949);
  }
}

.snow:nth-child(153) {
  opacity: .5381;
  animation: 24s linear -11s infinite fall-153;
  transform: translate(57.7985vw, -10px)scale(.2985);
}

@keyframes fall-153 {
  57.117% {
    transform: translate(50.3752vw, 57.117vh)scale(.2985);
  }

  to {
    transform: translate(54.0869vw, 100vh)scale(.2985);
  }
}

.snow:nth-child(154) {
  opacity: .4322;
  animation: 18s linear -18s infinite fall-154;
  transform: translate(28.9961vw, -10px)scale(.5414);
}

@keyframes fall-154 {
  46.816% {
    transform: translate(19.5551vw, 46.816vh)scale(.5414);
  }

  to {
    transform: translate(24.2756vw, 100vh)scale(.5414);
  }
}

.snow:nth-child(155) {
  opacity: .0717;
  animation: 29s linear -6s infinite fall-155;
  transform: translate(92.6355vw, -10px)scale(.7844);
}

@keyframes fall-155 {
  48.446% {
    transform: translate(97.823vw, 48.446vh)scale(.7844);
  }

  to {
    transform: translate(95.2293vw, 100vh)scale(.7844);
  }
}

.snow:nth-child(156) {
  opacity: .6247;
  animation: 25s linear -20s infinite fall-156;
  transform: translate(39.8531vw, -10px)scale(.7333);
}

@keyframes fall-156 {
  59.767% {
    transform: translate(43.9781vw, 59.767vh)scale(.7333);
  }

  to {
    transform: translate(41.9156vw, 100vh)scale(.7333);
  }
}

.snow:nth-child(157) {
  opacity: .6299;
  animation: 11s linear -13s infinite fall-157;
  transform: translate(70.5113vw, -10px)scale(.9076);
}

@keyframes fall-157 {
  57.166% {
    transform: translate(70.5823vw, 57.166vh)scale(.9076);
  }

  to {
    transform: translate(70.5468vw, 100vh)scale(.9076);
  }
}

.snow:nth-child(158) {
  opacity: .5221;
  animation: 15s linear -5s infinite fall-158;
  transform: translate(4.7705vw, -10px)scale(.4593);
}

@keyframes fall-158 {
  71.246% {
    transform: translate(11.2648vw, 71.246vh)scale(.4593);
  }

  to {
    transform: translate(8.01765vw, 100vh)scale(.4593);
  }
}

.snow:nth-child(159) {
  opacity: .6778;
  animation: 29s linear -6s infinite fall-159;
  transform: translate(16.4742vw, -10px)scale(.2039);
}

@keyframes fall-159 {
  76.461% {
    transform: translate(19.4783vw, 76.461vh)scale(.2039);
  }

  to {
    transform: translate(17.9763vw, 100vh)scale(.2039);
  }
}

.snow:nth-child(160) {
  opacity: .6654;
  animation: 23s linear -25s infinite fall-160;
  transform: translate(2.9665vw, -10px)scale(.5181);
}

@keyframes fall-160 {
  65.722% {
    transform: translate(-.3186vw, 65.722vh)scale(.5181);
  }

  to {
    transform: translate(1.32395vw, 100vh)scale(.5181);
  }
}

.snow:nth-child(161) {
  opacity: .1917;
  animation: 27s linear -18s infinite fall-161;
  transform: translate(19.1826vw, -10px)scale(.6756);
}

@keyframes fall-161 {
  46.319% {
    transform: translate(15.7911vw, 46.319vh)scale(.6756);
  }

  to {
    transform: translate(17.4869vw, 100vh)scale(.6756);
  }
}

.snow:nth-child(162) {
  opacity: .9834;
  animation: 28s linear -29s infinite fall-162;
  transform: translate(21.9553vw, -10px)scale(.7097);
}

@keyframes fall-162 {
  50.51% {
    transform: translate(17.289vw, 50.51vh)scale(.7097);
  }

  to {
    transform: translate(19.6222vw, 100vh)scale(.7097);
  }
}

.snow:nth-child(163) {
  opacity: .1445;
  animation: 25s linear -17s infinite fall-163;
  transform: translate(11.8541vw, -10px)scale(.5327);
}

@keyframes fall-163 {
  58.319% {
    transform: translate(12.6037vw, 58.319vh)scale(.5327);
  }

  to {
    transform: translate(12.2289vw, 100vh)scale(.5327);
  }
}

.snow:nth-child(164) {
  opacity: .9348;
  animation: 12s linear -13s infinite fall-164;
  transform: translate(10.3231vw, -10px)scale(.1608);
}

@keyframes fall-164 {
  71.459% {
    transform: translate(.9582vw, 71.459vh)scale(.1608);
  }

  to {
    transform: translate(5.64065vw, 100vh)scale(.1608);
  }
}

.snow:nth-child(165) {
  opacity: .1877;
  animation: 10s linear -5s infinite fall-165;
  transform: translate(56.5501vw, -10px)scale(.9195);
}

@keyframes fall-165 {
  38.697% {
    transform: translate(50.7256vw, 38.697vh)scale(.9195);
  }

  to {
    transform: translate(53.6379vw, 100vh)scale(.9195);
  }
}

.snow:nth-child(166) {
  opacity: .182;
  animation: 24s linear -6s infinite fall-166;
  transform: translate(37.4713vw, -10px)scale(.2398);
}

@keyframes fall-166 {
  43.907% {
    transform: translate(45.6918vw, 43.907vh)scale(.2398);
  }

  to {
    transform: translate(41.5816vw, 100vh)scale(.2398);
  }
}

.snow:nth-child(167) {
  opacity: .0991;
  animation: 22s linear -23s infinite fall-167;
  transform: translate(40.7398vw, -10px)scale(.1572);
}

@keyframes fall-167 {
  77.854% {
    transform: translate(40.8197vw, 77.854vh)scale(.1572);
  }

  to {
    transform: translate(40.7798vw, 100vh)scale(.1572);
  }
}

.snow:nth-child(168) {
  opacity: .1206;
  animation: 30s linear -5s infinite fall-168;
  transform: translate(65.6304vw, -10px)scale(.0565);
}

@keyframes fall-168 {
  50.907% {
    transform: translate(67.025vw, 50.907vh)scale(.0565);
  }

  to {
    transform: translate(66.3277vw, 100vh)scale(.0565);
  }
}

.snow:nth-child(169) {
  opacity: .1507;
  animation: 13s linear -7s infinite fall-169;
  transform: translate(62.3424vw, -10px)scale(.6179);
}

@keyframes fall-169 {
  30.495% {
    transform: translate(71.2868vw, 30.495vh)scale(.6179);
  }

  to {
    transform: translate(66.8146vw, 100vh)scale(.6179);
  }
}

.snow:nth-child(170) {
  opacity: .7159;
  animation: 16s linear -13s infinite fall-170;
  transform: translate(27.7797vw, -10px)scale(.9881);
}

@keyframes fall-170 {
  49.638% {
    transform: translate(28.683vw, 49.638vh)scale(.9881);
  }

  to {
    transform: translate(28.2314vw, 100vh)scale(.9881);
  }
}

.snow:nth-child(171) {
  opacity: .5584;
  animation: 25s linear -18s infinite fall-171;
  transform: translate(5.4157vw, -10px)scale(.8465);
}

@keyframes fall-171 {
  59.118% {
    transform: translate(8.0822vw, 59.118vh)scale(.8465);
  }

  to {
    transform: translate(6.74895vw, 100vh)scale(.8465);
  }
}

.snow:nth-child(172) {
  opacity: .599;
  animation: 21s linear -17s infinite fall-172;
  transform: translate(24.3209vw, -10px)scale(.8969);
}

@keyframes fall-172 {
  38.293% {
    transform: translate(25.7329vw, 38.293vh)scale(.8969);
  }

  to {
    transform: translate(25.0269vw, 100vh)scale(.8969);
  }
}

.snow:nth-child(173) {
  opacity: .5999;
  animation: 11s linear -25s infinite fall-173;
  transform: translate(18.1556vw, -10px)scale(.2814);
}

@keyframes fall-173 {
  73.211% {
    transform: translate(14.8862vw, 73.211vh)scale(.2814);
  }

  to {
    transform: translate(16.5209vw, 100vh)scale(.2814);
  }
}

.snow:nth-child(174) {
  opacity: .2898;
  animation: 20s linear -2s infinite fall-174;
  transform: translate(49.1772vw, -10px)scale(.6871);
}

@keyframes fall-174 {
  59.723% {
    transform: translate(54.9466vw, 59.723vh)scale(.6871);
  }

  to {
    transform: translate(52.0619vw, 100vh)scale(.6871);
  }
}

.snow:nth-child(175) {
  opacity: .4058;
  animation: 22s linear -19s infinite fall-175;
  transform: translate(7.2708vw, -10px)scale(.3888);
}

@keyframes fall-175 {
  38.548% {
    transform: translate(-1.0729vw, 38.548vh)scale(.3888);
  }

  to {
    transform: translate(3.09895vw, 100vh)scale(.3888);
  }
}

.snow:nth-child(176) {
  opacity: .3067;
  animation: 20s linear -14s infinite fall-176;
  transform: translate(70.0529vw, -10px)scale(.8879);
}

@keyframes fall-176 {
  76.755% {
    transform: translate(73.3341vw, 76.755vh)scale(.8879);
  }

  to {
    transform: translate(71.6935vw, 100vh)scale(.8879);
  }
}

.snow:nth-child(177) {
  opacity: .1307;
  animation: 19s linear -19s infinite fall-177;
  transform: translate(51.1345vw, -10px)scale(.6105);
}

@keyframes fall-177 {
  73.14% {
    transform: translate(60.6024vw, 73.14vh)scale(.6105);
  }

  to {
    transform: translate(55.8685vw, 100vh)scale(.6105);
  }
}

.snow:nth-child(178) {
  opacity: .3182;
  animation: 22s linear -8s infinite fall-178;
  transform: translate(83.474vw, -10px)scale(.1661);
}

@keyframes fall-178 {
  70.732% {
    transform: translate(82.944vw, 70.732vh)scale(.1661);
  }

  to {
    transform: translate(83.209vw, 100vh)scale(.1661);
  }
}

.snow:nth-child(179) {
  opacity: .1555;
  animation: 27s linear -27s infinite fall-179;
  transform: translate(30.7944vw, -10px)scale(.1135);
}

@keyframes fall-179 {
  37.678% {
    transform: translate(23.6254vw, 37.678vh)scale(.1135);
  }

  to {
    transform: translate(27.2099vw, 100vh)scale(.1135);
  }
}

.snow:nth-child(180) {
  opacity: .7641;
  animation: 20s linear -12s infinite fall-180;
  transform: translate(9.6803vw, -10px)scale(.2663);
}

@keyframes fall-180 {
  71.956% {
    transform: translate(9.1399vw, 71.956vh)scale(.2663);
  }

  to {
    transform: translate(9.4101vw, 100vh)scale(.2663);
  }
}

.snow:nth-child(181) {
  opacity: .5183;
  animation: 23s linear -1s infinite fall-181;
  transform: translate(59.6892vw, -10px)scale(.4551);
}

@keyframes fall-181 {
  58.147% {
    transform: translate(58.2738vw, 58.147vh)scale(.4551);
  }

  to {
    transform: translate(58.9815vw, 100vh)scale(.4551);
  }
}

.snow:nth-child(182) {
  opacity: .1427;
  animation: 18s linear -28s infinite fall-182;
  transform: translate(34.4148vw, -10px)scale(.1742);
}

@keyframes fall-182 {
  46.284% {
    transform: translate(32.2918vw, 46.284vh)scale(.1742);
  }

  to {
    transform: translate(33.3533vw, 100vh)scale(.1742);
  }
}

.snow:nth-child(183) {
  opacity: .0665;
  animation: 20s linear -13s infinite fall-183;
  transform: translate(94.7696vw, -10px)scale(.4413);
}

@keyframes fall-183 {
  42.47% {
    transform: translate(91.4597vw, 42.47vh)scale(.4413);
  }

  to {
    transform: translate(93.1147vw, 100vh)scale(.4413);
  }
}

.snow:nth-child(184) {
  opacity: .3326;
  animation: 22s linear -4s infinite fall-184;
  transform: translate(89.9632vw, -10px)scale(.025);
}

@keyframes fall-184 {
  47.49% {
    transform: translate(91.6109vw, 47.49vh)scale(.025);
  }

  to {
    transform: translate(90.7871vw, 100vh)scale(.025);
  }
}

.snow:nth-child(185) {
  opacity: .0299;
  animation: 13s linear -6s infinite fall-185;
  transform: translate(77.9582vw, -10px)scale(.4015);
}

@keyframes fall-185 {
  72.339% {
    transform: translate(78.1515vw, 72.339vh)scale(.4015);
  }

  to {
    transform: translate(78.0549vw, 100vh)scale(.4015);
  }
}

.snow:nth-child(186) {
  opacity: .7563;
  animation: 29s linear -11s infinite fall-186;
  transform: translate(14.0008vw, -10px)scale(.2808);
}

@keyframes fall-186 {
  70.571% {
    transform: translate(18.3261vw, 70.571vh)scale(.2808);
  }

  to {
    transform: translate(16.1635vw, 100vh)scale(.2808);
  }
}

.snow:nth-child(187) {
  opacity: .2509;
  animation: 28s linear -14s infinite fall-187;
  transform: translate(86.3921vw, -10px)scale(.4794);
}

@keyframes fall-187 {
  34.619% {
    transform: translate(84.5535vw, 34.619vh)scale(.4794);
  }

  to {
    transform: translate(85.4728vw, 100vh)scale(.4794);
  }
}

.snow:nth-child(188) {
  opacity: .3444;
  animation: 13s linear -20s infinite fall-188;
  transform: translate(56.4171vw, -10px)scale(.6669);
}

@keyframes fall-188 {
  64.383% {
    transform: translate(66.027vw, 64.383vh)scale(.6669);
  }

  to {
    transform: translate(61.2221vw, 100vh)scale(.6669);
  }
}

.snow:nth-child(189) {
  opacity: .2819;
  animation: 15s linear -7s infinite fall-189;
  transform: translate(47.5269vw, -10px)scale(.1754);
}

@keyframes fall-189 {
  40.634% {
    transform: translate(57.2632vw, 40.634vh)scale(.1754);
  }

  to {
    transform: translate(52.3951vw, 100vh)scale(.1754);
  }
}

.snow:nth-child(190) {
  opacity: .3152;
  animation: 24s linear -10s infinite fall-190;
  transform: translate(80.0931vw, -10px)scale(.8304);
}

@keyframes fall-190 {
  71.957% {
    transform: translate(77.0425vw, 71.957vh)scale(.8304);
  }

  to {
    transform: translate(78.5678vw, 100vh)scale(.8304);
  }
}

.snow:nth-child(191) {
  opacity: .2201;
  animation: 28s linear -8s infinite fall-191;
  transform: translate(54.4831vw, -10px)scale(.4965);
}

@keyframes fall-191 {
  52.576% {
    transform: translate(53.4809vw, 52.576vh)scale(.4965);
  }

  to {
    transform: translate(53.982vw, 100vh)scale(.4965);
  }
}

.snow:nth-child(192) {
  opacity: .0409;
  animation: 21s linear -10s infinite fall-192;
  transform: translate(3.0161vw, -10px)scale(.4812);
}

@keyframes fall-192 {
  78.672% {
    transform: translate(11.1418vw, 78.672vh)scale(.4812);
  }

  to {
    transform: translate(7.07895vw, 100vh)scale(.4812);
  }
}

.snow:nth-child(193) {
  opacity: .9614;
  animation: 26s linear -12s infinite fall-193;
  transform: translate(10.1065vw, -10px)scale(.4587);
}

@keyframes fall-193 {
  61.196% {
    transform: translate(4.1262vw, 61.196vh)scale(.4587);
  }

  to {
    transform: translate(7.11635vw, 100vh)scale(.4587);
  }
}

.snow:nth-child(194) {
  opacity: .4504;
  animation: 20s linear -30s infinite fall-194;
  transform: translate(30.8196vw, -10px)scale(.1201);
}

@keyframes fall-194 {
  46.396% {
    transform: translate(28.8333vw, 46.396vh)scale(.1201);
  }

  to {
    transform: translate(29.8265vw, 100vh)scale(.1201);
  }
}

.snow:nth-child(195) {
  opacity: .3083;
  animation: 28s linear -19s infinite fall-195;
  transform: translate(32.5366vw, -10px)scale(.0411);
}

@keyframes fall-195 {
  49.714% {
    transform: translate(27.0402vw, 49.714vh)scale(.0411);
  }

  to {
    transform: translate(29.7884vw, 100vh)scale(.0411);
  }
}

.snow:nth-child(196) {
  opacity: .0659;
  animation: 14s linear -22s infinite fall-196;
  transform: translate(1.6265vw, -10px)scale(.9296);
}

@keyframes fall-196 {
  45.659% {
    transform: translate(4.3341vw, 45.659vh)scale(.9296);
  }

  to {
    transform: translate(2.9803vw, 100vh)scale(.9296);
  }
}

.snow:nth-child(197) {
  opacity: .148;
  animation: 25s linear -6s infinite fall-197;
  transform: translate(16.2263vw, -10px)scale(.2803);
}

@keyframes fall-197 {
  60.857% {
    transform: translate(8.6595vw, 60.857vh)scale(.2803);
  }

  to {
    transform: translate(12.4429vw, 100vh)scale(.2803);
  }
}

.snow:nth-child(198) {
  opacity: .506;
  animation: 10s linear -15s infinite fall-198;
  transform: translate(49.1245vw, -10px)scale(.8425);
}

@keyframes fall-198 {
  74.003% {
    transform: translate(46.2448vw, 74.003vh)scale(.8425);
  }

  to {
    transform: translate(47.6847vw, 100vh)scale(.8425);
  }
}

.snow:nth-child(199) {
  opacity: .0327;
  animation: 16s linear -26s infinite fall-199;
  transform: translate(52.2607vw, -10px)scale(.9551);
}

@keyframes fall-199 {
  33.735% {
    transform: translate(61.462vw, 33.735vh)scale(.9551);
  }

  to {
    transform: translate(56.8614vw, 100vh)scale(.9551);
  }
}

.snow:nth-child(200) {
  opacity: .2738;
  animation: 17s linear -22s infinite fall-200;
  transform: translate(55.1879vw, -10px)scale(.638);
}

@keyframes fall-200 {
  63.752% {
    transform: translate(54.9536vw, 63.752vh)scale(.638);
  }

  to {
    transform: translate(55.0708vw, 100vh)scale(.638);
  }
}

.v-btn {
  color: #fff;
  opacity: .5;
  text-decoration: none;
  transition: opacity .3s ease-in-out;
  position: fixed;
  top: 0;
}

.v-btn:hover {
  opacity: 1;
}

.v-btn.prev-v-btn {
  background: linear-gradient(135deg, #333 50%, #0000 50%);
  padding: .5rem 3rem 3rem .5rem;
  left: 0;
}

.v-btn.next-v-btn {
  background: linear-gradient(45deg, #0000 50%, #333 50%);
  padding: .5rem .5rem 3rem 3rem;
  right: 0;
}

/*# sourceMappingURL=index.6e70f4ff.css.map */
